<template>
  <div class="container">
    <div
      v-if="error.statusCode === 404"
      class="error-content"
    >
      <section class="card">
        <header>
          <i
            v-for="i in 3"
            :key="i"
            class="dot"
          />
        </header>
        <main>
          <p>Ooops! 這裡好像出了點問題...</p>
          <i class="icon-system_empty-1" />
          <div class="text-404">
            <p>404</p>
            <p>
              <span>無法找到頁面</span>
              <span>Page not found</span>
            </p>
          </div>
        </main>
      </section>
      <v-btn
        v-if="!isElifeInspectionFormError"
        color="primary"
        href="/"
        class="btn-go-home"
      >
        回到首頁
      </v-btn>
    </div>
    <h1 v-else>
      異常錯誤
    </h1>
  </div>
</template>

<script>
export default {
  props: ['error'],
  computed: {
    isElifeInspectionFormError () {
      return this.error?.customData?.error === 'ELIFE_INSPECTION_FORM_ERROR'
    }
  },
  head () {
    return {
      meta: [
        { hid: 'robots', name: 'robots', content: 'noindex, nofollow' },
        { hid: 'googlebot', name: 'googlebot', content: 'noindex, nofollow' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  min-height: calc(100vh - #{$headerHeight});
  min-width: 100%;
  background-color: #f2f2f2;
}
.error-content {
  height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card {
  max-width: 510px;
  width: 100%;
  margin-bottom: 75px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.3);
  overflow: hidden;
  header {
    height: 51px;
    padding-left: 17px;
    display: flex;
    align-items: center;
    background-color: $mainColor;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.3);
    i {
      $size: 14px;
      height: $size;
      width: $size;
      border-radius: 50%;
      background-color: #fff;
      margin-right: 12px;
    }
  }
  main {
    padding: 40px 70px;
    text-align: center;
    > p {
      margin-bottom: 29px;
      color: #999999;
      font-size: pxTorem(26px);
      line-height: pxTorem(37px);
      font-weight: bold;
    }
    > i {
      display: block;
      margin-bottom: 46px;
      color: #999999;
      font-size: pxTorem(150px);
    }
    .text-404 {
      color: $mainColor;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        &:first-child {
          margin-right: 16px;
          font-size: pxTorem(74px);
          line-height: pxTorem(74px);
          font-weight: 700;
        }
        &:last-child {
          text-align: left;
          font-size: pxTorem(19px);
          line-height: pxTorem(26px);
          font-weight: 400;
          span {
            display: block;
          }
        }
      }
    }
  }
}
.container .error-content .btn-go-home {
  height: auto;
  padding: 10px 75px;
  ::v-deep {
    span {
      font-size: pxTorem(17px);
      line-height: pxTorem(24px);
    }
  }
}

/** 手機 */
@media screen and (max-width: $mobileWidth) {
  .container {
    min-height: calc(100vh - #{$headerHeightMobile});
  }
  .error-content {
    min-height: calc(100vh - #{$headerHeightMobile});
  }
  .card {
    max-width: 75vw;
    header {
      height: 28px;
      padding-left: 10px;
      i {
        $size: 8px;
        height: $size;
        width: $size;
        margin-right: 7px;
      }
    }
    main {
      padding: 20px 30px;
      > p {
        margin-bottom: 17px;
        font-size: pxTorem(16px);
        line-height: pxTorem(22px);
      }
      > i {
        margin-bottom: 25px;
        font-size: pxTorem(80px);
      }
      .text-404 {
        p {
          &:first-child {
            margin-right: 10px;
            font-size: pxTorem(41px);
            line-height: pxTorem(41px);
          }
          &:last-child {
            font-size: pxTorem(14px);
            line-height: pxTorem(20px);
          }
        }
      }
    }
  }
  .container .error-content .btn-go-home {
    width: 80%;
    ::v-deep {
      span {
        font-size: pxTorem(16px);
        line-height: pxTorem(22px);
      }
    }
  }
}
</style>
